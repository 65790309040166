export enum Languages {
    Afrikanns="Afrikanns",
    Albanian="Albanian",
    Arabic="Arabic",
    Armenian="Armenian",
    Basque="Basque",
    Bengali="Bengali",
    Bulgarian="Bulgarian",
    Catalan="Catalan",
    Cambodian="Cambodian",
    ChineseMandarin="Chinese (Mandarin)",
    Croation="Croation",
    Czech="Czech",
    Danish="Danish",
    Dutch="Dutch",
    English="English",
    Estonian="Estonian",
    Fiji="Fiji",
    Finnish="Finnish",
    French="French",
    Georgian="Georgian",
    German="German",
    Greek="Greek",
    Gujarati="Gujarati",
    Hebrew="Hebrew",
    Hindi="Hindi",
    Hungarian="Hungarian",
    Icelandic="Icelandic",
    Indonesian="Indonesian",
    Irish="Irish",
    Italian="Italian",
    Japanese="Japanese",
    Javanese="Javanese",
    Korean="Korean",
    Latin="Latin",
    Latvian="Latvian",
    Lithuanian="Lithuanian",
    Macedonian="Macedonian",
    Malay="Malay",
    Malayalam="Malayalam",
    Maltese="Maltese",
    Maori="Maori",
    Marathi="Marathi",
    Mongolian="Mongolian",
    Nepali="Nepali",
    Norwegian="Norwegian",
    Persian="Persian",
    Polish="Polish",
    Portuguese="Portuguese",
    Punjabi="Punjabi",
    Quechua="Quechua",
    Romanian="Romanian",
    Russian="Russian",
    Samoan="Samoan",
    Serbian="Serbian",
    Slovak="Slovak",
    Slovenian="Slovenian",
    Spanish="Spanish",
    Swahili="Swahili",
    Swedish ="Swedish",
    Tamil="Tamil",
    Tatar="Tatar",
    Telugu="Telugu",
    Thai="Thai",
    Tibetan="Tibetan",
    Tonga="Tonga",
    Turkish="Turkish",
    Ukranian="Ukranian",
    Urdu="Urdu",
    Uzbek="Uzbek",
    Vietnamese="Vietnamese",
    Welsh="Welsh",
    Xhosa="Xhosa",
}